jQuery(document).ready(function($){
  jQuery(document).foundation();


  ;(function( $, window, document, undefined )
  {
    $.fn.doubleTapToGo = function( params )
    {
      if( !( 'ontouchstart' in window ) &&
        !navigator.msMaxTouchPoints &&
        !navigator.userAgent.toLowerCase().match( /windows phone os 7/i ) ) return false;

      this.each( function()
      {
        var curItem = false;

        $( this ).on( 'click', function( e )
        {
          var item = $( this );
          if( item[ 0 ] != curItem[ 0 ] )
          {
            e.preventDefault();
            curItem = item;
          }
        });

        $( document ).on( 'click touchstart MSPointerDown', function( e )
        {
          var resetItem = true,
            parents   = $( e.target ).parents();

          for( var i = 0; i < parents.length; i++ )
            if( parents[ i ] == curItem[ 0 ] )
              resetItem = false;

          if( resetItem )
            curItem = false;
        });
      });
      return this;
    };
  })( jQuery, window, document );
  $( '#nav li:has(ul)' ).doubleTapToGo();

  $('.hamburger').click(function(){
    $(this).toggleClass('is-active');
    $('.top-nav-menu').toggleClass('is-active');
  });

  $('.search-open').click(function(){
    $('.search-container').addClass('is-active');
  });

  $('.search-close').click(function(){
    $('.search-container').removeClass('is-active');
  });

});